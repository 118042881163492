import { graphql, useStaticQuery } from 'gatsby'

import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import { Link } from 'gatsby'
import React from 'react'

const About = () => {
  const {
    site: {
      siteMetadata: { title },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Layout>
      <Helmet>
        <html className="about" lang="ja" />
        <title>{`会社概要 | ${title}`}</title>
        <meta
          name="description"
          content="本社・支店のご案内、沿革、これまでの実績をご紹介いたします。"
        />
      </Helmet>
      <div className="common-header clearfix">
        <h1>会社概要</h1>
        <div className="breadcrumb">
          <li>
            <Link to={`/`}>トップページ</Link>
          </li>
          /<li>会社概要</li>
        </div>
      </div>
      <div className="main-wrapper">
        <div className="row">
          <div className="container">
            <div className="contents">
              <div className="common-link">
                <AnchorLink to="/about#branch" title="本社・支店のご案内">
                  本社・支店のご案内
                </AnchorLink>
              </div>
              <div className="common-link">
                <AnchorLink to="/about#history" title="沿革">
                  沿革
                </AnchorLink>
              </div>
              <div className="clear"></div>
              <div className="common-link">
                <AnchorLink to="/about#achievement" title="これまでの実績">
                  これまでの実績
                </AnchorLink>
              </div>
              <div className="clear"></div>
            </div>

            <table>
              <tbody>
                <tr>
                  <th>商　号</th>
                  <td>株式会社アイテック</td>
                </tr>
                <tr>
                  <th>資本金</th>
                  <td>10,000,000円</td>
                </tr>
                <tr>
                  <th>代表取締役</th>
                  <td>前川 卓志</td>
                </tr>
                <tr>
                  <th className="multi">事業内容</th>
                  <td className="multi">
                    駐車場設備機器の製造・販売・設置・保守管理業務
                    <br />
                    時間貸駐車場の運営・管理・集金業務
                    <br />
                    機械器具設置工事
                    <br />
                    とび・土工工事業
                    <br />
                    電気工事業、電気通信工事業の設計、施工・管理の請負ほか
                  </td>
                </tr>
                <tr>
                  <th>グループ会社</th>
                  <td>
                    <a
                      href="http://pkshatech.com/ja/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      PKSHA Technology
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div id="branch" className="row">
          <div className="h-wrapper">
            <h2>本社・支店のご案内</h2>
          </div>
        </div>
        <div className="row">
          <div className="container office">
            <div className="common-link">
              <AnchorLink to="/about#branch-tokyo" title="東京本社">
                東京本社
              </AnchorLink>
            </div>
            <div className="common-link">
              <AnchorLink to="/about#branch-osaka" title="大阪支店">
                大阪支店
              </AnchorLink>
            </div>
            <div className="common-link">
              <AnchorLink to="/about#branch-nagoya" title="名古屋支店">
                名古屋支店
              </AnchorLink>
            </div>
            <div className="common-link">
              <AnchorLink to="/about#branch-fukuoka" title="福岡支店">
                福岡支店
              </AnchorLink>
            </div>
            <div className="common-link">
              <AnchorLink to="/about#branch-sendai" title="仙台事業所">
                仙台事業所
              </AnchorLink>
            </div>
            <div className="common-link">
              <AnchorLink to="/about#factory-shinyokohama" title="新横浜工場">
                新横浜工場
              </AnchorLink>
            </div>

            <div className="clear"></div>
            <div id="branch-tokyo" className="box">
              <p className="place">東京本社</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6479.859367540179!2d139.74111926854746!3d35.7033479362684!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188c45b1ce8531%3A0x67fa5a30bf7f8401!2z44CSMTEyLTAwMDQg5p2x5Lqs6YO95paH5Lqs5Yy65b6M5qW977yS5LiB55uu77yR4oiS77yT!5e0!3m2!1sja!2sjp!4v1625137543360!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒112-0004　東京都文京区後楽2-1-3　NS飯田橋ビル１階
                <br />
                TEL：03-5802-6871（代表） <br className="sp-only" />
                FAX：03-5802-6872（代表）
              </p>
            </div>
            <div className="border sp-only"></div>

            <div id="branch-osaka" className="box">
              <p className="place">大阪支店</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6558.085611171307!2d135.49249716852776!3d34.72931538909625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6000e43a7874fa79%3A0x62eca54ec6bb13a4!2z44CSNTMyLTAwMTEg5aSn6Ziq5bqc5aSn6Ziq5biC5reA5bed5Yy66KW_5Lit5bO277yW5LiB55uu77yT4oiS77yT77yS!5e0!3m2!1sja!2sjp!4v1625137609268!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒532-0011　大阪府大阪市淀川区西中島6-3-32 第2新大阪ビル4階
                <br />
                TEL：06-6838-9331&nbsp;
                <br className="sp-only" />
                FAX：06-6838-9351
              </p>
            </div>

            <div className="clear"></div>
            <div className="border"></div>

            <div id="branch-nagoya" className="box">
              <p className="place">名古屋支店</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6522.631053115456!2d136.88451116853682!3d35.17368756514951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x600376db68827e33%3A0xd9be6bf0e63afe9!2z44CSNDUwLTAwMDIg5oSb55-l55yM5ZCN5Y-k5bGL5biC5Lit5p2R5Yy65ZCN6aeF77yT5LiB55uu77yS4oiS77yR77yR!5e0!3m2!1sja!2sjp!4v1625137675621!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒450-0002　愛知県名古屋市中村区名駅3-2-11 シークスビル1階
                <br />
                TEL：052-588-6051&nbsp;
                <br className="sp-only" />
                FAX：052-588-6052
              </p>
            </div>
            <div className="border sp-only"></div>

            <div id="branch-fukuoka" className="box">
              <p className="place">福岡支店</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6646.303119029485!2d130.40899786850596!3d33.601371848707245!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x354191dd2ab98b0f%3A0xa4d6fc2d343dff1e!2z44CSODEyLTAwNDQg56aP5bKh55yM56aP5bKh5biC5Y2a5aSa5Yy65Y2D5Luj77yS5LiB55uu77yS77yR4oiS77yR77yY!5e0!3m2!1sja!2sjp!4v1625137784755!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒812-0044 福岡県福岡市博多区千代2-21-18 インヘリッツ田原1階
                <br />
                TEL：092-631-0018&nbsp;
                <br className="sp-only" />
                FAX：092-631-0019
              </p>
            </div>

            <div className="clear"></div>
            <div className="border"></div>

            <div id="branch-sendai" className="box">
              <p className="place">仙台事業所</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3132.7019816599973!2d140.87542081622755!3d38.26321737967341!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f8a282385434e07%3A0x17824ac6eb8979be!2z44CSOTgwLTAwMTQg5a6u5Z-O55yM5LuZ5Y-w5biC6Z2S6JGJ5Yy65pys55S677yS5LiB55uu77yR4oiS77yR!5e0!3m2!1sja!2sjp!4v1624765094131!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒980-0014　宮城県仙台市青葉区本町2-1-1 安藤本町ビル4階
                <br />
                TEL：022-393-7744&nbsp;
                <br className="sp-only" />
                FAX：022-393-7769
              </p>
            </div>
            <div className="border sp-only"></div>

            <div id="factory-shinyokohama" className="box">
              <p className="place">新横浜工場</p>

              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3246.7817673087693!2d139.61087811618304!3d35.53439028022889!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60185f4a46091709%3A0x439fca8a639144e9!2z44CSMjIzLTAwNTgg56We5aWI5bed55yM5qiq5rWc5biC5riv5YyX5Yy65paw5ZCJ55Sw5p2x77yY5LiB55uu77yT77yZ4oiS77yR77yQ!5e0!3m2!1sja!2sjp!4v1624764894657!5m2!1sja!2sjp"
                width="430"
                height="240"
                frameborder="0"
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>

              <p className="address">
                〒223-0058　神奈川県横浜市港北区新吉田東8-39-10
                <br />
                TEL：045-541-5671
                <br className="sp-only" />
                FAX：045-544-4879
              </p>
            </div>

            <div className="clear"></div>
          </div>
        </div>
        <div className="row">
          <div className="h-wrapper">
            <h3>許可・登録</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <table>
              <tbody>
                <tr>
                  <th className="multi">
                    <br />
                    一般建設業
                    <br />
                  </th>
                  <td className="multi">
                    とび・土木工事業
                    <br />
                    電気工事業
                    <br />
                    通信電気工事業
                  </td>
                  <td className="multi">
                    <br />
                    許可番号　東京都知事　許可（般-4）第156549号
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>主要納品先</h3>
          </div>
        </div>

        <div className="row">
          <div className="container suppliers">
            <div className="wrapper">
              株式会社アークリンク
              <br />
              株式会社エイブルパーキング
              <br />
              株式会社NBパーキング
              <br />
              エフ・ステップ株式会社
              <br />
              株式会社オートリ
              <br />
              オリックス不動産株式会社
              <br />
              京王不動産株式会社
              <br />
              相模石油株式会社
              <br />
              株式会社シーピーエス
              <br />
              新明和工業株式会社
              <br />
              株式会社スペースプラン
              <br />
              東洋カーマックス株式会社
              <br />
              トヨタメディアサービス株式会社
              <br />
              株式会社日本駐車場サービス
              <br />
              日本パーキング株式会社
              <br />
              株式会社パーキング３６５
              <br />
              株式会社パークジャパン
              <br />
              三菱地所パークス株式会社
              <br />
              株式会社ユアー・パーキング
              <br />
            </div>
            <div className="wrapper">
              アダチ産業株式会社
              <br />
              エスパークマネジメント株式会社
              <br />
              株式会社NTTル・パルク
              <br />
              株式会社 LLC
              <br />
              小田急電鉄株式会社
              <br />
              極東開発パーキング株式会社
              <br />
              京成不動産株式会社
              <br />
              三交不動産株式会社
              <br />
              株式会社シンプル
              <br />
              スターツアメニティー株式会社
              <br />
              公益財団法人東京都道路整備保全公社
              <br />
              株式会社トップシステム
              <br />
              日本総合サービス株式会社
              <br />
              日本駐車場メンテナンス株式会社
              <br />
              株式会社日本プロパティソリューションズ
              <br />
              株式会社パークエイジ
              <br />
              株式会社マック
              <br />
              株式会社ユアーズ・コーポレーション
              <br />
              株式会社ユニー
              <br />
            </div>
            <div className="crear"></div>
            <p>※50音順</p>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>取引先銀行</h3>
          </div>
        </div>

        <div className="row">
          <div className="container bank clearfix">
            <p>三井住友銀行　飯田橋支店</p>
          </div>
        </div>

        <div id="history" className="row">
          <div className="h-wrapper">
            <h2>沿革</h2>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <table>
              <tbody>
                <tr>
                  <th>平成6年（1994年）</th>
                  <td className="multi">
                    7月　駐車場機器の製造開発を目的とし、東京都新宿区二十騎町にて創業。
                    <br />
                    10月　ゲート式パーキングシステム1号機を、東京恵比寿の立体駐車場に設置。
                  </td>
                </tr>
                <tr>
                  <th>平成7年（1995年） </th>
                  <td className="multi">
                    10月　大手駐車場設備機器メーカーのゲート式パーキングシステム開発を受託、順次ファミリーレストランに設置。
                  </td>
                </tr>
                <tr>
                  <th>平成9年（1997年） </th>
                  <td className="multi">
                    9月　株式会社イチノセを設立。
                    <br />
                    大手駐車場設備機器メーカーのロック板式パーキングシステムにおける2台管理・3台管理精算機の開発を受託し、ヒット商品となる。
                  </td>
                </tr>
                <tr>
                  <th>平成13年（2001年）</th>
                  <td className="multi">
                    7月　株式会社アイテックに商号変更し、新宿区新小川町に本社機能を移転。新たな駐車場設備機器メーカーとして自社製品を開発・製造・販売。
                  </td>
                </tr>
                <tr>
                  <th>平成14年（2002年）</th>
                  <td className="multi">
                    8月　新宿区山吹町にテクノロジーセンター（山吹工場）を開設。
                  </td>
                </tr>
                <tr>
                  <th>平成15年(2003年)</th>
                  <td className="multi">7月　第6期売上高2億2600万円。</td>
                </tr>
                <tr>
                  <th>平成16年（2004年）</th>
                  <td className="multi">
                    1月　フロントロック板を開発。
                    <br />
                    5月　大阪市西中島に大阪支店を開設。
                    <br />
                    7月　第7期売上高4億6000万円。
                    <br />
                    8月　バーコードサービス券システムを開発。
                  </td>
                </tr>
                <tr>
                  <th>平成17年（2005年）</th>
                  <td className="multi">
                    5月　バリアフリー（昇降式）ロック板を開発。
                    <br />
                    6月　防犯カメラの遠隔監視システムを開発。1号機を埼玉県越谷市千間台に設置。
                    <br />
                    8月　通算販売台数 精算機1,000台を達成。
                  </td>
                </tr>
                <tr>
                  <th>平成18年（2006年）</th>
                  <td className="multi">
                    3月　ゲート式駐車場システムを開発。1号機を東京湯島に設置。
                    <br />
                    6月　通算販売台数 ロック板10,000台を達成。
                    <br />
                    8月　名古屋市西区に名古屋支店を開設。
                    <br />
                    11月　通算販売台数 精算機2,000台を達成。
                  </td>
                </tr>
                <tr>
                  <th>平成19年（2007年）</th>
                  <td className="multi">
                    7月　アルコールチェッカー付精算機を開発し、設置。
                    <br />
                    9月　通算販売台数 精算機3,000台、ロック板20,000台を達成。
                  </td>
                </tr>
                <tr>
                  <th>平成20年（2008年）</th>
                  <td className="multi">
                    8月　通算販売台数 精算機4,000台、ロック板30,000台を達成。
                    <br />
                    11月　福岡市博多区に福岡支店を開設。
                  </td>
                </tr>
                <tr>
                  <th>平成21年（2009年）</th>
                  <td className="multi">
                    1月　月極専用ゲートを開発し、設置。
                    <br />
                    7月　ナンバー認識用3メガピクセル（300万画素）カメラを開発。ナンバー認識システムを開発。
                    <br />
                    11月　駐車場検索システムを開発し、サイトを立ち上げ。
                    <br />
                    12月　防犯用1.3メガピクセル（130万画素）カメラを開発。グローバル・ネットワークシステムを開発。業務拡張に伴い、名古屋支店を名古屋市中村区へ移転。
                  </td>
                </tr>
                <tr>
                  <th>平成22年（2010年）</th>
                  <td className="multi">
                    1月　韓国へフラップ式精算機システム納品開始
                    <br />
                    3月　ナンバー認識システム「ロックレス」販売開始。
                  </td>
                </tr>
                <tr>
                  <th>平成23年（2011年）</th>
                  <td className="multi">
                    6月　韓国へゲート式精算機システム納品開始
                    <br />
                    9月　交通系電子マネー決済対応
                  </td>
                </tr>
                <tr>
                  <th>平成24年（2012年）</th>
                  <td className="multi">
                    8月　ゲート用ナンバー認識システムを開発し、大阪市内の駐車場に設置。
                    <br />
                    10月　トヨタメディアサービスの充電器G-Stationと精算機を連携、豊田スタジアムなどに設置
                    <br />
                    12月　ロックレス駐車場が累積600ヶ所、6,000車室を超えました。
                  </td>
                </tr>
                <tr>
                  <th>平成25年（2013年）</th>
                  <td className="multi">
                    3月　スマートフォンから精算できるアプリ開発し、ロックレス駐車場に展開開始。
                    <br />
                    7月　多摩市内のゲート式駐車場に月極用RFIDシステムを導入。
                    <br />
                    10月　NET精算機との高速レスポンス維持の為、センターシステムを増強・更新。
                    <br />
                    12月　ロックレス駐車場が累積1,000ヶ所、10,000車室を超えました
                  </td>
                </tr>
                <tr>
                  <th>平成26年（2014年）</th>
                  <td className="multi">
                    1月　提携店舗にスマートフォンを設置し、駐車証明書を読ませてサービス情報をデータ送信する「NETサービス」開始
                    <br />
                    5月　スマートフォンから精算できるゲート用アプリを開発し、立川の駐車場数カ所に納品。
                  </td>
                </tr>
                <tr>
                  <th>平成27年（2015年）</th>
                  <td className="multi">
                    3月　業務拡張のため電気工事に特化した工事請負部門（開発事業部）を新設し、業務拠点として品川区に品川事業所を開設。
                    <br />
                    7月　本社を新宿区新小川町から文京区後楽2-1-3 へ移転。
                  </td>
                </tr>
                <tr>
                  <th>平成28年（2016年）</th>
                  <td className="multi">
                    8月　クレジットカード決済のセキュリティ基準であるPCIDSS準拠認定を取得。
                    <br />
                    9月　QT-netポイントからGポイントへの交換サービス開始。
                  </td>
                </tr>
                <tr>
                  <th>平成29年（2017年）</th>
                  <td className="multi">
                    2月　浜松町第1駐車場で時間貸し車室の予約サービスを開始。
                    <br />
                    5月　ｄポイント進呈・利用サービスを開始
                    <br />
                    12月　チケットレスゲートを開発。1号機を埼玉県東松山市に設置。
                  </td>
                </tr>
                <tr>
                  <th>平成30年（2018年）</th>
                  <td className="multi">
                    10月　Web版遠隔管理システムi-BARMS（アイバーンズ）サービス開始。
                  </td>
                </tr>
                <tr>
                  <th>令和元年（2019年）</th>
                  <td className="multi">
                    7月　株式会社PKSHA Technologyのグループに参画。
                  </td>
                </tr>
                <tr>
                  <th>令和2年（2020年）</th>
                  <td className="multi">
                    7月　バーコード決済サービスを開始。
                    <br />
                    11月　ICカード対応クレジットカード決済サービスを開始。
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div id="achievement" className="row">
          <div className="h-wrapper">
            <h2>これまでの実績</h2>
          </div>
        </div>

        <div className="row">
          <div className="container achievement">
            <div className="box">
              <p>ロックレス駐車場オープン車室数累計</p>
              <div className="contents">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/about-graph-01.webp"
                  />
                  <img
                    className="about-graph-01"
                    src="/assets/images/common/about-graph-01.png"
                    alt="about-graph-01"
                    height="288"
                  />
                </picture>
              </div>
            </div>
            <div className="box">
              <p>オープン車室数累計</p>
              <div className="contents">
                <picture>
                  <source
                    type="image/webp"
                    srcset="/assets/images/common/about-graph-02.webp"
                  />
                  <img
                    className="about-graph-02"
                    src="/assets/images/common/about-graph-02.png"
                    alt="about-graph-02"
                    height="294"
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="h-wrapper">
            <h3>駐車場設備機器以外の開発</h3>
          </div>
        </div>

        <div className="row">
          <div className="container">
            <p>
              大手パン工場向けに照合機を開発し、設置販売。（平成11年9月）
              <br />
              機械メーカー向けにデータ転送機を開発し、販売。（平成15年7月）
              <br />
              データ分配機を開発し、販売。（平成20年7月）
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default About
